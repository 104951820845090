import Swal from 'sweetalert2';
import { TitleCasePipe } from '@angular/common';
import { TitleBreadcrumbPipe } from '../pipe/title-breadcrumb.pipe';
import { Validators } from '@angular/forms';
import { CustomValidators } from './custom-validators';

export const surtechUtils = {
	parseDataToSelect2Data: (data: any, key1: string, key2: string) => {
		let titleCasePipe: TitleCasePipe = new TitleCasePipe();
		let parsedData: any = [];
		data.forEach((element: any) => {
			let seldata = { id: element[key1], text: titleCasePipe.transform(element[key2]) };
			parsedData.push(seldata);
		});
		return parsedData;
	},

	alertDefaults: {
		icon: 'error',
		title: '¡Error!',
		confirmButtonText: 'Continuar',
		cancelButtonText: 'Cancelar',
		allowOutsideClick: false,
		customClass: {
			confirmButton: 'btn btn-primary py-3 px-4 mr-2 ml-2',
			cancelButton: 'btn btn-secondary py-3 px-4 mr-2 ml-2',
			input: 'text-center',
		},
		buttonsStyling: false,
	},

	toastDefaults: {
		icon: 'error',
		toast: true,
		position: 'top-end',
		showConfirmButton: false,
		timer: 3000,
		timerProgressBar: true,
		didOpen: (toast: any) => {
			toast.addEventListener('mouseenter', Swal.stopTimer);
			toast.addEventListener('mouseleave', Swal.resumeTimer);
		},
	},

	mergeServerMessages: (message: any | string) => {
		let msg = '';

		if (typeof message === 'string') {
			msg = message;
		} else {
			for (let key in message) {
				for (let key2 in message[key]) {
					msg += message[key][key2] + '\n\n';
				}
			}
		}

		return msg;
	},

	formatBytes: (bytes: number, decimals: number = 2) => {
		if (bytes === 0) return '0 Bytes';
		const k = 1024;
		const dm = decimals < 0 ? 0 : decimals;
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
		const i = Math.floor(Math.log(bytes) / Math.log(k));
		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
	},

	statusMessages: (code: number | string = 0) => {
		const messages: any = {
			/* Error de servidor */
			'500': 'Ha ocurrido un error desconocido al procesar su solicitud, por favor vuelva a intentarlo',

			/* Ejecutando acciones (toasts)*/
			'registrando-usuario': 'Registrando usuario.',
			'iniciando-sesion': 'Iniciando sesión.',
			'completando-registro': 'Completando registro de usuario.',
			'validanto-codigo-seguridad': 'Validando código de seguridad.',
			'actualizando-contraseña': 'Actualizando contraseña.',
			'presentando-denuncia': 'Se está presentando su denuncia, por favor no actualice ni salga de esta página hasta que el proceso finalice.',
			'solicitud-valida': 'Solicitud valida.',
			'formato-imagen-valido': 'Imagen cargada, por favor seleccione el área que desea utilizar y de click en establecer imagen.',
			'actualizando-informacion-personal': 'Actualizando su información personal.',
			'recuperando-contraseña': 'Generando solicitud de recuperación.',
			'ingresando-denuncia': 'Validando el código de ingreso.',
			'agendando-contacto': 'Agendando cita con el primer contacto.',

			/* Errores generales (toasts) */
			'error-informacion-proporcionada': 'Por favor verifique la información proporcionada.',
			'error-sesion-inactiva': 'Debe tener una sesión activa para continuar.',
			'error-ruta-no-encontrada': '404 Ruta no encontrada.',
			'error-medidas-cautelares': 'Por favor justifique las medidas cautelares seleccionadas.',
			'formato-imagen-invalido': 'Formato de imagen no soportado.',

			/* Errores listados (toasts) */
			'error-listado-estados': 'Ha ocurrido un error desconocido al obtener el listado de estados.',
			'error-listado-municipios': 'Ha ocurrido un error desconocido al obtener el listado de municipios.',
			'error-listado-generos': 'Ha ocurrido un error desconocido al obtener el listado de géneros.',
			'error-listado-instancias': 'Ha ocurrido un error desconocido al obtener el listado de instancias.',
			'error-listado-subinstancias': 'Ha ocurrido un error desconocido al obtener el listado de sub instancias.',
			'error-listado-grupos-participante': 'Ha ocurrido un error desconocido al obtener el listado de grupos de participantes.',
			'error-listado-tipos-pruebas': 'Ha ocurrido un error desconocido al obtener el listado de los tipos de pruebas.',
			'error-listado-denuncias': 'Ha ocurrido un error desconocido al obtener el listado de las denuncias.',

			/* Registro */
			'titulo-registro-completo': '¡Usuario Registrado!',
			'registro-completo': 'Se ha registrado exitosamente, por favor revise su bandeja de entrada, inicie sesión y complete sus datos para finalizar.',
			'error-registro': 'Ha ocurrido un error desconocido al registrar el usuario, por favor vuelve a intentarlo.',
			'error-creacion-user-correo-existente': 'No se ha podido realizar el registro, el correo ya se encuentra en uso. Por favor registre otro correo o intente recuperar su cuenta.',

			/* Login */
			'titulo-codigo-seguridad': '¡Código de Verificación!',
			'codigo-seguridad': 'Introduce el código de seguridad que te hemos enviado a tu correo.',
			'sesion-iniciada': 'Sesión iniciada.',
			'error-login-invalido': 'Usuario o clave inválido.',
			'error-login-user-no-verificado': 'No ha verificado su correo electrónico, por favor verifique su cuenta mediante el enlace enviado a su correo o intente recuperar su cuenta.', //el usuario debe verificar su correo

			/* Completar Datos */
			'titulo-registro-completado': '¡Registro Completado!',
			'registro-completado': 'Ha completado los datos de su registro exitosamente.',
			'error-registro-completado': 'Ha ocurrido un error desconocido al completar sus datos, por favor vuelve a intentarlo.',
			'advertencia-completar-datos': 'Por favor debe completar sus datos para continuar.',

			/* Validación del correo */
			'titulo-validacion-correo-valida': '¡Usuario Validado!',
			'validacion-correo-valida': 'Se ha validado correctamente su dirección de correo electrónico. Por favor ingrese para completar su registro.',
			'titulo-validacion-correo-invalida': '¡Error!',
			'validacion-correo-invalida': 'Este enlace de validación expiró.',

			/* Recuperacion de Contraseña */
			'titulo-recuperacion-contraseña-valida': '¡Recuperación Solicitada!',
			'recuperacion-contraseña-valida': 'Si su información es correcta, recibirá un correo para recuperar su contraseña.',
			'error-recuperacion-contraseña': 'Ha ocurrido un error desconocido al recuperar su contraseña, por favor vuelve a intentarlo.',
			'error-solicitud-recuperacion-contraseña': 'Esta solicitud de cambio de contraseña es inválida o expiró.',
			'error-cambio-contraseña': 'Ha ocurrido un error desconocido al recuperar su contraseña, por favor vuelve a intentarlo.',
			'titulo-contraseña-actualizada': '¡Contraseña Actualizada!',
			'contraseña-actualizada': 'Su contraseña ha sido actualizada correctamente.',

			/* Agendar Primer Contacto */
			'subinstancia-primer-contacto-vacio': 'No hay primer contacto asociado a la escuela o dependencia. Por favor inténtelo más tarde.',
			'titulo-agendar-contacto': '¡Agendar Primer Contacto!',
			'agendar-contacto': '¿Desea agendar su cita con el primer contacto en la fecha: ${fecha}?. Esta acción no se puede deshacer.',
			'titulo-cita-agendada': '¡Cita Agendada!',
			'cita-agendada': 'Se ha agendado si cita correctamente.',
			'usuario-citas-incompletas': 'No fue posible agendar su cita, usted aún tiene citas pendientes en su calendario.',
			'conflicto-otras-citas': 'No fue posible agendar su cita, el primer contacto posee un evento agendado en la fecha seleccionada, por favor recargue la página y vuelva a intentarlo.',

			/* Presentar Denuncia */
			'titulo-presentar-denuncia': '¡Presentación de Denuncia!',
			'presentar-denuncia': '¿Desea continuar y presentar la denuncia?',
			'titulo-denuncia-presentada': '¡Denuncia Presentada!',

			/* Presentar Denuncia (toasts) */
			'error-validar-datos-denunciante': 'Por favor validar los datos del denunciante.',
			'error-invalid-evidence-type': 'Por favor introduzca un tipo de medio válido.',
			'error-invalid-measure-type': 'Por favor introduzca un tipo medida cautelar válida.',
			'error-añadir-victima': 'Por favor debe añadir al menos una víctima a la denuncia.',
			'error-validar-datos-victima-indice': 'Por favor validar los datos de la víctima #${indice}.',
			'error-añadir-tutor-victima': 'Por favor agregar al menos un tutor a la víctima #${indice}.',
			'error-validar-tutor1-victima': 'Por favor validar los datos del tutor 1 de la víctima #${indice}.',
			'error-validar-tutor2-victima': 'Por favor validar los datos del tutor 2 de la víctima #${indice}.',
			'error-añadir-responsable': 'Por favor debe añadir al menos una persona señalada de responsable a la denuncia.',
			'error-validar-datos-responsable-indice': 'Por favor validar los datos del la persona señalada de responsable #${indice}.',
			'error-validar-tutor1-responsable': 'Por favor validar los datos del tutor 1 del la persona señalada de responsable #${indice}.',
			'error-validar-tutor2-responsable': 'Por favor validar los datos del tutor 2 del la persona señalada de responsable#${indice}.',
			'error-añadir-tutor-responsable': 'Por favor agregar al menos un tutor de la persona señalada de responsable #${indice}.',
			'error-descripcion-hechos': 'Por favor describa detalladamente los hechos.',
			'error-validar-testigo': 'Por favor validar los datos del testigo #${indice}.',
			'error-observaciones': 'Por favor compruebe sus observaciones.',
			'error-firma': 'Por favor firme para presentar su denuncia.',

			/* Unirse a denuncia */
			'titulo-ingreso-denuncia': '¡Ingreso Exitoso!',
			'ingreso-denuncia': 'Ha ingresado correctamente al proceso ${codigo} en calidad de ${tipo_participante}.',
			'error-codigo-invalido': 'El código proporcionado no es válido.',
			'error-codigo-usado': 'El código ya fue utilizado.',
			'tipo-usuario-erroneo': 'El tipo de usuario es incorrecto',
			'error-actualizar-involved': 'No fue posible realizar la asignación al proceso.',
			'error-denuncia-invalida': 'La denuncia es inválida',

			/* Cambio decontraseña */
			'pregunta-cambio-contraseña': '¿Esta seguro que desea cambiar su contraseña?',

			/* Profile */
			'informacion-enviada': 'Su informacion se ha enviado',
			'actualizado-exitoso': 'Se ha actualizado con exito su información',

			/* Contacto */
			'titulo-contacto-exitoso': '¡Mensaje Enviado!',
			'contacto-exitoso': 'Se ha enviado su información con éxito, nos comunicaremos con usted a la brevedad posible.',

			/* Errores en servicios */
			'error-creacion-user-location': 'Ha ocurrido un error desconocido durante la creación del usuario.',
			'error-creacion-user-person': 'Ha ocurrido un error desconocido durante la creación del usuario.',
			'error-creacion-user': 'Ha ocurrido un error desconocido durante la creación del usuario.',
			'error-campos-invalidos': 'Los datos proporcionados son inválidos.',
			'error-user-restringido': 'El usuario no tiene acceso al sistema.',
			'error-recaptcha-invalido': 'No aprobó la validación de seguridad anti-spam.',
			'user-erroneo': 'Ha ocurrido un error desconocido al obtener los datos del usuario.',
			'error-actualizar-user': 'Ha ocurrido un error desconocido al actualizar los datos del usuario.',
			'error-actualizar-user-location': 'Ha ocurrido un error desconocido al actualizar los datos del usuario.',
			'error-actualizar-user-person': 'Ha ocurrido un error desconocido al actualizar los datos del usuario.',
			'error-eliminar-user-picture': 'Ha ocurrido un error desconocido al eliminar la imagen del usuario.',
			'error-actualizar-user-person-picture': 'Ha ocurrido un error desconocido al actualizar la imagen del usuario.',
			'error-actualizar-user-picture': 'Ha ocurrido un error desconocido al actualizar la imagen del usuario.',
			'error-token-expiro': 'La solicitud expiró.',
			'error-codigo-expiro': 'El código expiró',

			/* BreadCrumb */
			complaints: 'Denuncias',
			security: 'Seguridad',
			'change password': 'Cambio de Contraseña',
			'personal information': 'Información Personal',
			list: 'Listar',
			create: 'Crear',
			process: 'Proceso',
			active: 'Activas',
			closed: 'Cerradas',
			archived: 'Archivadas',
			information: 'Información',
			activity: 'Actividad',
			evidences: 'Evidencias',
			documents: 'Documentos',
			flowcharts: 'Diagramas de Flujo',
			dashboard: 'Dashboard',
			schedule: 'Agenda'
		};

		return messages[code];
	},

	TitleCasePipe: (text: string): string => {
		let titleCasePipe: TitleCasePipe = new TitleCasePipe();
		return titleCasePipe.transform(text);
	},

	emptyObject: (object: any): boolean => {
		return Object.values(object).every(x => x === null || x === '');
	},

	validatorsSets: {
		names: [Validators.required, Validators.minLength(2), Validators.maxLength(50), CustomValidators.names()],
		gender: [Validators.required, Validators.minLength(1)],
		state: [Validators.required, Validators.minLength(1)],
		municipality: [Validators.required, Validators.minLength(1)],
		email: [Validators.required, Validators.email],
		password: [Validators.required, Validators.minLength(8), Validators.maxLength(50), CustomValidators.password()],
		birthday: [Validators.required, CustomValidators.dateString('DDMMYYYY')],
		phone: [Validators.required, Validators.maxLength(10), Validators.minLength(10)],
		colony: [Validators.required, Validators.maxLength(50), Validators.minLength(5)],
		zipcode: [Validators.required, Validators.maxLength(5), Validators.minLength(5)],
		street: [Validators.required, Validators.maxLength(100), Validators.minLength(5)],
		instreet: [Validators.maxLength(50)],
		extstreet: [Validators.maxLength(50)],
	},

	getParticipantTypeString: (id: number): string => {
		let cat = ['', 'Primer Contacto', 'Denunciante', 'Víctima', 'Señalado de Responsable', 'Testigo', 'Tutor'];

		return cat[id];
	},
};
