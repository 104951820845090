import { Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from 'src/app/core/exports/custom-validators';
import { ApiService } from 'src/app/core/services/api/api.service';
import { surtechUtils } from 'src/app/core/exports/surtechUtils';
import AirDatepicker from 'air-datepicker';
import localeEs from 'air-datepicker/locale/es';
import { UserComplete } from 'src/app/core/interfaces/user-complete';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { Observable, Subject, takeUntil } from 'rxjs';
import { UserData } from 'src/app/core/interfaces/user-data';
import { select, Store } from '@ngrx/store';
import { UserDataFull } from 'src/app/core/reducer/user-data/user-data.selector';
import { Logout, UserDataSave } from 'src/app/core/reducer/user-data/user-data.actions';
import { JsEncode } from 'src/app/core/exports/JsEncode';
import { DefaultValueAccessor } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

const original = DefaultValueAccessor.prototype.registerOnChange;

DefaultValueAccessor.prototype.registerOnChange = function (fn) {
	return original.call(this, value => {
		const trimmed = typeof value === 'string' ? value.trim() : value;
		return fn(trimmed);
	});
};

@Component({
	selector: 'app-complete-data',
	templateUrl: './complete-data.component.html',
	styleUrls: ['./complete-data.component.scss'],
	host: { class: 'flex flex-1 items-center justify-center surtech-fix-ng-flex main' },
})
export class CompleteDataComponent implements OnInit, OnDestroy {
	step = false;
	completeData: any;
	statesList: any = [];
	municipalityList: any = [];
	genderList: any = [];
	canComplete: boolean = true;

	loader: boolean = false;

	UserData$?: Observable<any>;
	UserDataFull: UserData = {} as UserData;
	private _unsubscribe = new Subject<void>();

	constructor(private _router: Router, private formBuilder: FormBuilder, private toastr: ToastrService, private apiService: ApiService, private store: Store, @Inject(DOCUMENT) private document: Document, private renderer: Renderer2) {
		this.UserData$ = this.store.pipe(select(UserDataFull));
		this.UserData$.pipe(takeUntil(this._unsubscribe)).subscribe(data => {
			this.UserDataFull = data;
		});

		this.completeData = this.formBuilder.group({
			birthday: ['', surtechUtils.validatorsSets.birthday],
			gender: ['', surtechUtils.validatorsSets.gender],
			phone: ['', surtechUtils.validatorsSets.phone],
			state: ['', surtechUtils.validatorsSets.state],
			municipality: ['', surtechUtils.validatorsSets.municipality],
			colony: ['', surtechUtils.validatorsSets.colony],
			zipcode: ['', surtechUtils.validatorsSets.zipcode],
			street: ['', surtechUtils.validatorsSets.street],
			instreet: ['', surtechUtils.validatorsSets.instreet],
			extstreet: ['', surtechUtils.validatorsSets.extstreet],
		});
	}

	ngOnDestroy(): void {
		this._unsubscribe.next();
		this._unsubscribe.complete();
		this.renderer.removeClass(this.document.body, 'login');
		this.renderer.removeClass(this.document.body, 'bg-white');
	}

	ngOnInit(): void {
		this.renderer.addClass(this.document.body, 'login');
		this.renderer.addClass(this.document.body, 'bg-white');

		this.apiService
			.list_cat_countries_states()
			.pipe(takeUntil(this._unsubscribe))
			.subscribe({
				next: _data => {
					if (_data.success) {
						this.statesList = surtechUtils.parseDataToSelect2Data(_data.catalog, 'id_cat_country_state', 'cat_text');
						this.completeData.get('state').addValidators(CustomValidators.select2(this.statesList));
					}
				},
				error: _error => {
					this.toastr.error(surtechUtils.statusMessages('error-listado-estados'));
				},
			});

		this.apiService
			.list_cat_genders()
			.pipe(takeUntil(this._unsubscribe))
			.subscribe({
				next: _data => {
					if (_data.success) {
						this.genderList = surtechUtils.parseDataToSelect2Data(_data.catalog, 'id_cat_gender', 'cat_text');
						this.completeData.get('gender').addValidators(CustomValidators.select2(this.statesList));
					} else {
						this.toastr.error(surtechUtils.statusMessages('error-listado-generos'));
					}
				},
				error: _error => {
					this.toastr.error(surtechUtils.statusMessages('error-listado-generos'));
				},
			});
	}

	submmitComplete() {
		if (this.completeData.valid) {
			this.canComplete = false;
			let controls = this.completeData.controls;

			let userComplete: UserComplete = {
				date_birth: (controls.birthday.value as string).replace('/', '-').replace('/', '-'),
				id_cat_gender: controls.gender.value,
				phone: controls.phone.value,
				id_municipio: controls.municipality.value,
				state: controls.state.value,
				colonia: (controls.colony.value as string).toLowerCase(),
				zip: controls.zipcode.value,
				street: (controls.street.value as string).toLowerCase(),
				internal_num: (controls.instreet.value as string).toLowerCase(),
				external_num: (controls.extstreet.value as string).toLowerCase(),
			};

			this.toastr.info(surtechUtils.statusMessages('completando-registro'));
			this.loader = true;

			this.apiService
				.update_person_location(userComplete)
				.pipe(takeUntil(this._unsubscribe))
				.subscribe({
					next: _data => {
						this.loader = false;
						this.toastr.clear();

						if (_data.success && _data.user_data) {
							// Esperar por el reducer para actualizar complete data
							Swal.fire({
								...(surtechUtils.alertDefaults as any),
								...{
									icon: 'success',
									title: surtechUtils.statusMessages('titulo-registro-completado'),
									text: surtechUtils.statusMessages('registro-completado'),
								},
							});

							// Guardamos la información del usaurio encriptada en el localStorage
							localStorage.setItem('user_data', _data.user_data);

							// Desencriptamos la data del usuario
							let data_user: UserData = JSON.parse(JsEncode.decrypt(_data.user_data));

							// Disparamos el evento de actualización de la data del usuario
							this.store.dispatch(
								new UserDataSave({
									UserData: data_user as UserData,
								})
							);

							this.UserDataFull.street ? this._router.navigateByUrl('/dashboard') : this._router.navigateByUrl('/complete-data');
						}
					},
					error: _error => {
						this.canComplete = true;
						this.loader = false;
						this.toastr.clear();

						Swal.fire({
							...(surtechUtils.alertDefaults as any),
							...{
								text: surtechUtils.statusMessages('error-registro-completado'),
							},
						});
					},
				});
		} else {
			this.canComplete = true;
			this.toastr.error(surtechUtils.statusMessages('error-informacion-proporcionada'));
		}
	}

	validateGender() {
		this.completeData.controls['gender'].markAllAsTouched();
	}

	validateStateMunicipality() {
		this.completeData.controls['state'].markAllAsTouched();
		this.completeData.controls['municipality'].markAllAsTouched();
	}

	stateChanged() {
		this.municipalityList = [];
		this.apiService
			.list_cat_states_municipalities(this.completeData.value.state)
			.pipe(takeUntil(this._unsubscribe))
			.subscribe(
				data => {
					if (data.success) {
						this.municipalityList = surtechUtils.parseDataToSelect2Data(data.catalog, 'id_cat_state_municipality', 'cat_text');
						this.completeData.get('municipality').addValidators(CustomValidators.select2(this.municipalityList));
					} else {
						this.toastr.error(surtechUtils.statusMessages('error-listado-municipios'));
					}
				},
				_error => {
					this.toastr.error(surtechUtils.statusMessages('error-listado-municipios'));
				}
			);
	}

	initializeLib() {
		setTimeout(() => {
			new AirDatepicker('#birthday', {
				locale: localeEs,
				onSelect: e => {
					if (e.formattedDate) {
						this.completeData.controls['birthday'].setValue((e.formattedDate as string).replace('/', '').replace('/', ''));
					} else {
						this.completeData.controls['birthday'].setValue(e.formattedDate);
					}
				},
				maxDate: new Date(),
			});
		}, 0);
	}

	backHome() {
		this.store.dispatch(new Logout());
		this._router.navigateByUrl('/home/login');
	}
}
