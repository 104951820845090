import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Angular2AirDatepickerModule } from 'angular2-air-datepicker';
import { NgSelect2Module } from 'ng-select2';
import { NgIconsModule } from '@ng-icons/core';
import { FeatherEye, FeatherEyeOff, FeatherX, FeatherKey, FeatherMail, FeatherUser, FeatherCalendar, FeatherPhone, FeatherMap, FeatherMapPin, FeatherHome, FeatherBriefcase, FeatherArchive, FeatherLock, FeatherSearch, FeatherPlus, FeatherUpload, FeatherBarChart2, FeatherBell, FeatherToggleRight, FeatherHelpCircle, FeatherChevronDown, FeatherCircle, FeatherHash, FeatherNavigation, FeatherUsers, FeatherSquare, FeatherTriangle, FeatherUserPlus, FeatherUserMinus, FeatherClipboard, FeatherMinusCircle, FeatherPlusCircle, FeatherPenTool, FeatherDelete, FeatherGlobe, FeatherInfo, FeatherEdit, FeatherFilePlus, FeatherFileText, FeatherBookOpen, FeatherList, FeatherActivity, FeatherFolder, FeatherGitPullRequest, FeatherTag, FeatherCrosshair, FeatherUserCheck, FeatherAlertOctagon, FeatherCornerDownRight, FeatherShield, FeatherBook, FeatherExternalLink } from '@ng-icons/feather-icons';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NglrxPipesModule } from '@nglrx/pipes';
import { TooltipModule } from 'ng2-tooltip-directive';
import { NgxMaskModule } from 'ngx-mask';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { ComplaintReviewComponent } from '../modules/dashboard/modules/complaints/modules/complaint-review/complaint-review.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { FullCalendarModule } from '@fullcalendar/angular'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import { TrimInputDirective } from '../core/directives/trim-input.directive';
import { ActionValidatorDirective } from '../core/directives/security.directive';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { ToastrModule } from 'ngx-toastr';
import { SpinnerComponent } from '../core/components/spinner/spinner.component';
import { UcFirstPipe } from '../core/pipe/uc-first.pipe';

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin,
  timeGridPlugin,
  listPlugin
]);

@NgModule({
  declarations: [
    SpinnerComponent,
    ComplaintReviewComponent,
    TrimInputDirective,
    ActionValidatorDirective,
    UcFirstPipe
  ],
  imports: [
    CommonModule,
    Angular2AirDatepickerModule,
    NglrxPipesModule,
    NgSelect2Module,
    NgxMaskModule.forRoot(),
    NgIconsModule.withIcons({ FeatherEye, FeatherEyeOff, FeatherX, FeatherKey, FeatherMail, FeatherUser, FeatherCalendar, FeatherPhone, FeatherMap, FeatherMapPin, FeatherHome, FeatherBriefcase, FeatherArchive, FeatherLock, FeatherSearch, FeatherPlus, FeatherUpload, FeatherBarChart2, FeatherBell, FeatherToggleRight, FeatherHelpCircle, FeatherChevronDown, FeatherCircle, FeatherHash, FeatherNavigation, FeatherUsers, FeatherSquare, FeatherTriangle, FeatherUserPlus, FeatherUserMinus, FeatherClipboard, FeatherMinusCircle, FeatherPlusCircle, FeatherPenTool, FeatherDelete, FeatherGlobe, FeatherInfo, FeatherEdit, FeatherFilePlus, FeatherFileText, FeatherBookOpen, FeatherList, FeatherActivity, FeatherFolder, FeatherGitPullRequest, FeatherTag, FeatherCrosshair, FeatherUserCheck, FeatherAlertOctagon, FeatherCornerDownRight, FeatherShield, FeatherBook, FeatherExternalLink }),
    FormsModule,
    ReactiveFormsModule,
    TooltipModule,
    NgxDropzoneModule,
    ImageCropperModule,
    FullCalendarModule,
    NgxTrimDirectiveModule,
    ToastrModule.forRoot({
      closeButton: true,
      progressBar: true
    })
  ],
  exports: [
    CommonModule,
    Angular2AirDatepickerModule,
    NglrxPipesModule,
    NgSelect2Module,
    NgxMaskModule,
    NgIconsModule,
    FormsModule,
    ReactiveFormsModule,
    TooltipModule,
    NgxDropzoneModule,
    ImageCropperModule,
    FullCalendarModule,
    ComplaintReviewComponent,
    TrimInputDirective,
    ActionValidatorDirective,
    NgxTrimDirectiveModule,
    ToastrModule,
    SpinnerComponent,
    UcFirstPipe
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class SharedModule { }
