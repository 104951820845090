import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import * as moment from 'moment';

export const CustomValidators = {

  match: (matchTo: string, reverse?: boolean): ValidatorFn => {
    return (control: AbstractControl):  ValidationErrors | null => {
      if (control.parent && reverse) {
        const c = (control.parent?.controls as any)[matchTo] as AbstractControl;
        if (c) {
          c.updateValueAndValidity();
        }
        return null;
      }
      return !!control.parent && !!control.parent.value && control.value === (control.parent?.controls as any)[matchTo].value ? null : { matching: true };
    };
  },

  names: (): ValidatorFn => {
    return (control: AbstractControl):  ValidationErrors | null => {
      const isText = /^([a-zA-ZÀ-ÿñÑ]+[ ]{0,1})*$/.test(control.value);
      return isText ? null : {notText: true};
    };
  },

  optionalNames: (): ValidatorFn => {
    return (control: AbstractControl):  ValidationErrors | null => {
      if(control.value == '' || control.value == null) return null;
      const isText = /^([a-zA-ZÀ-ÿñÑ]+[ ]{0,1})*$/.test(control.value);
      return isText ? null : {notText: true};
    };
  },

  password: (): ValidatorFn => {
    return (control: AbstractControl):  ValidationErrors | null => {
      const isPass = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(control.value);
      return isPass ? null : {notPass: true};
    };
  },

  dateString: (format: string): ValidatorFn => {
    return (control: AbstractControl, ):  ValidationErrors | null => {
      let momentDate = moment(control.value, format);
      let date = momentDate.toDate();
      return !isNaN(date.getTime()) ? null : {notDate: true};
    };
  },
  
  optionalDateString: (format: string): ValidatorFn => {
    return (control: AbstractControl, ):  ValidationErrors | null => {
      if(control.value == '' || control.value == null) return null;
      let momentDate = moment(control.value, format);
      let date = momentDate.toDate();
      return !isNaN(date.getTime()) ? null : {notDate: true};
    };
  },

  // Corregir este validador
  select2: (list: any): ValidatorFn => {
    return (control: AbstractControl, ):  ValidationErrors | null => {
      let data_filter = list.filter( (element: { id: any; }) => element.id == control.value )
      return (data_filter.length > 0) ? null : null; //{ notExist: true } ;
    };
  }
}
