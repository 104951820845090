import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthHomeGuard } from '../core/guard/auth-home.guard';
import { AuthGuard } from '../core/guard/auth.guard';
import { NotFoundGuard } from '../core/guard/not-found.guard';
import { QueryParamGuard } from '../core/guard/query-param.guard';
import { CompleteDataComponent } from '../modules/complete-data/complete-data.component';
import { BaseComponent } from './base.component';

const routes: Routes = [
  {
    path: '',
    component: BaseComponent,
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
      },
      {
        path: 'home',
        canActivate: [QueryParamGuard, AuthHomeGuard],
        loadChildren: () => import('../modules/home/home.module').then(m => m.HomeModule)
      },
      {
        path: 'complete-data',
        canActivate: [AuthGuard],
        data: {
          url: 'complete-data'
        },
        component: CompleteDataComponent
      },
      {
        path: 'dashboard',
        canActivate: [AuthGuard],
        loadChildren: () => import('../modules/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
    ]
  },
  {
    path: '**',
    pathMatch: 'full',
    canActivate: [NotFoundGuard],
    component: BaseComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class BaseRoutingModule { }
