import { Action } from '@ngrx/store';
import { UserData, AccessToken } from '../../interfaces/user-data';

export enum UserDataActionsTypes {
    UserData    = '[User Data] UserData Data',
    SaveToken   = '[Save Token] Save Token',
    Logout      = '[Logout] Logout'
}

export class UserDataSave implements Action {
    readonly type = UserDataActionsTypes.UserData;
    constructor(public payload: { UserData: UserData }) { }
}

export class SaveToken implements Action {
    readonly type = UserDataActionsTypes.SaveToken;
    constructor(public payload: { AccessToken: AccessToken }) { }
}

export class Logout implements Action {
    readonly type = UserDataActionsTypes.Logout;
    constructor() { }
}

export type UserDataActions = UserDataSave | SaveToken | Logout