import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { catchError, Observable, throwError, map, finalize, takeUntil, Subject } from 'rxjs';
import { JsEncode } from '../../exports/JsEncode';
import { select, Store } from '@ngrx/store';
import { Logout, SaveToken, UserDataSave } from '../../reducer/user-data/user-data.actions';
import { AccessToken, UserData } from '../../interfaces/user-data';
import { UserDataFull, AccessTokenSelector } from '../../reducer/user-data/user-data.selector';
import { surtechUtils } from '../../exports/surtechUtils';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
	UserData$?: Observable<any>;
	UserDataFull: UserData = {} as UserData;

	Token$?: Observable<any>;
	DataToken: AccessToken = {} as AccessToken;
	private _unsubscribe = new Subject<void>();

	constructor(private store: Store, private toastr: ToastrService, private router: Router) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const token: string = localStorage.getItem('x_access_token') || '';
		const fingerprint: string = localStorage.getItem('fingerprint') || '';

		// Introducir el token en el header para todas las peticiones
		const request = req.clone({
			headers: req.headers.append('x-access-token', `${token}`).append('x-access-fingerprint', `${fingerprint}`),
		});

		// Peticiones
		return next.handle(request).pipe(
			map((evt: any) => {
				if (evt instanceof HttpResponse) {
					if (evt.body && evt.body.success) {

						// Si la peticion contesta la información del usario encriptada
						if (evt.body.user_data) {
							// Guardamos la información del usaurio encriptada en el localStorage
							localStorage.removeItem('user_data');
							localStorage.setItem('user_data', evt.body.user_data);

							// Desencriptamos la data del usuario
							let data_user: UserData = JSON.parse(JsEncode.decrypt(evt.body.user_data));
							data_user.url_picture = data_user.url_picture ? data_user.url_picture + '?t=' + JSON.stringify(new Date()) : '';

							// Disparamos el evento de actualización de la data del usuario
							this.store.dispatch(
								new UserDataSave({
									UserData: data_user as UserData,
								})
							);

							// ??
							this.UserData$ = this.store.pipe(select(UserDataFull));

							// ??
							this.UserData$.pipe(takeUntil(this._unsubscribe)).subscribe(data => {
								this.UserDataFull = data;
							});
						}

						// Si la peticion contesta el token del usario encriptado
						if (evt.headers.get('x-access-token')) {
							const token = evt.headers.get('x-access-token') || '';

							// Guardamos la información del token encriptada en el localStorage
							localStorage.setItem('x_access_token', token);

							// Desencriptamos el token del usuario
							let AccessToken: AccessToken = {
								token: token,
								DataToken: JSON.parse(JsEncode.decrypt(token)),
							};

							// Disparamos el evento de actualización del token del usuario
							this.store.dispatch(
								new SaveToken({
									AccessToken: AccessToken as AccessToken,
								})
							);

							// ??
							this.Token$ = this.store.pipe(select(AccessTokenSelector));

							// ??
							this.Token$.pipe(takeUntil(this._unsubscribe)).subscribe(data => {
								this.DataToken = data;
							});
						}
					}
				}
				return evt;
			}),
			catchError((error: HttpErrorResponse) => {
				var errorCode = error.error.status;

				if (error.error.message) {
					//toastErrors

					let toastErrors = ['', '', ''];

					//this.toastr.error(surtechUtils.statusMessages());

					//alertError

					let ignoreAlerts = ['', '', ''];

					Swal.fire({
						...(surtechUtils.alertDefaults as any),
						...{
							text: surtechUtils.statusMessages(error.error.message),
						},
					});
				} else {
					Swal.fire({
						...(surtechUtils.alertDefaults as any),
						...{
							text: surtechUtils.statusMessages('500'),
						},
					});
				}

				if (errorCode === 403 || errorCode === 401 || errorCode === 421) {
					this.store.dispatch(new Logout());
					this.router.navigateByUrl('/home/login');
				}

				return throwError(() => new Error('error'));
			}),
			finalize(() => {
				// ?? Se ve interesante esto
				// setTimeout(() => {
				//     this.loaderService.isLoading.next(false);
				// }, 1);
			})
		);
	}
}
