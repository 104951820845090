import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanDeactivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';
import { surtechUtils } from '../exports/surtechUtils';

@Injectable({
  providedIn: 'root'
})
export class NotFoundGuard implements CanActivate {

  constructor(
    private router: Router,
    private toastr: ToastrService
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    let UserData: string = (localStorage.getItem('user_data') as any) || null;
    let token: string = (localStorage.getItem('x_access_token') as any) || null;
    this.toastr.warning(surtechUtils.statusMessages('error-ruta-no-encontrada'))
    if (token && UserData) {
      this.router.navigateByUrl('/dashboard');
      return false
    } else {
      this.router.navigateByUrl('/home');
      return true
    }
  }
}
