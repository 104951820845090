import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanDeactivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';
import { surtechUtils } from '../exports/surtechUtils';

@Injectable({
  providedIn: 'root'
})
export class QueryParamGuard implements CanActivate {

  constructor(
    private router: Router,
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const urlTree = this.router.parseUrl(state.url);

    //mensaje de validacion de correo
    if(route.queryParamMap.has('mailval')) {

      const mailval = urlTree.queryParams['mailval'];

      if(mailval == 1) {

        Swal.fire({... (surtechUtils.alertDefaults as any), ... {
          icon: 'success',
          title: surtechUtils.statusMessages('titulo-validacion-correo-valida'),
          text: surtechUtils.statusMessages('validacion-correo-valida')
        }});

      } else {

        Swal.fire({... (surtechUtils.alertDefaults as any), ... {
          title: surtechUtils.statusMessages('titulo-validacion-correo-invalida'),
          text: surtechUtils.statusMessages('validacion-correo-invalida')
        }});

      }

      this.router.navigate([], {queryParams: {'mailval': null},queryParamsHandling: 'merge'})

    }

    return true
    
  }
}
