import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Directive({
  selector: '[appTrimInput]'
})
export class TrimInputDirective {
  
  @Input() paragraphMode = false;

  constructor(private el: ElementRef, private control : NgControl) {
    el.nativeElement.onblur = function(){
      const regexInside = /( ){2,}/gmi;
      const regexStart = /^( )/gmi;

      let trim = el.nativeElement.value.trim();
      trim = trim.replace(regexInside, ' ');
      trim = trim.replace(regexStart, '');

      (control as any).control.setValue(trim);
    }
  }

  @HostListener('input',['$event']) onEvent($event: any){

    // Whitespace
    const regexInside = /( ){2,}/gmi;
    const regexStart = /^( )/gmi;

    // Paragraph
    const regexParagraphStart = /^[a-z]/gmi;
    //const regexParagraphStart = /(^[a-z]|\n[a-z])/gmi;

    const capitalizeFirstLetter = (s: string) => s.split(' ').map(w => w.charAt(0).toUpperCase() + w.slice(1)).join(' ');

    let lastCaretPos = $event.target.selectionStart;
    let value = this.el.nativeElement.value;

    if(this.paragraphMode){

      if (regexParagraphStart.test(value)) {

        value = value.replace(regexParagraphStart, (x: any) => { return x.toUpperCase(); });
        (this.control as any).control.setValue(value);
        $event.target.setSelectionRange(lastCaretPos,lastCaretPos);
        
      }

    } else {

      if(regexStart.test(value)){

        value = value.trim().toLowerCase();
        (this.control as any).control.setValue(capitalizeFirstLetter(value));
        lastCaretPos--;
        $event.target.setSelectionRange(lastCaretPos,lastCaretPos);

      }

      if(regexInside.test(value)){

        value = value.toLowerCase();
        value = value.replace(regexInside, ' ');
        (this.control as any).control.setValue(capitalizeFirstLetter(value));
        lastCaretPos--;
        $event.target.setSelectionRange(lastCaretPos,lastCaretPos);

      } else {

        value = value.toLowerCase();
        (this.control as any).control.setValue(capitalizeFirstLetter(value));
        $event.target.setSelectionRange(lastCaretPos,lastCaretPos);

      }

    }

    

    //
    

    /*if(regexInside.test(value)){
      value = value.trim().toLowerCase();
      value = value.replace(regexInside, ' ');
      value = value.replace(regexStart, '');
      $event.target.setSelectionRange((lastCaretPos as any) -1,(lastCaretPos as any) -1);
    }

    (this.control as any).control.setValue(capitalizeFirstLetter(value));

    if(this.paragraphMode){
      if(regexParagraphStart.test(value)){
        value = value.replace(regexParagraphStart, function(x: any){return x.toUpperCase()});
        (this.control as any).control.setValue(value);
        $event.target.setSelectionRange((lastCaretPos as any),(lastCaretPos as any));
      }
    } else {
      (this.control as any).control.setValue(capitalizeFirstLetter(value));
    }*/
  }
}