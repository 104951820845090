import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserRegister } from 'src/app/core/interfaces/user-register';
import { UserLogin } from 'src/app/core/interfaces/user-login';
import { TwoSteps } from 'src/app/core/interfaces/two-steps';
import { UserComplete } from 'src/app/core/interfaces/user-complete';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) { }

  apiURL = environment.apiURL;
  //apiURL = 'http://localhost/surtechudg-back-v2/public/api/';

  //Registrar nuevo usuario
  add_user(user: UserRegister): Observable<any> {
    let data = {
      names: user.first_name,
      last_names: user.last_name,
      password: user.password,
      email: user.email
    };

    return this.http.post<UserRegister>(this.apiURL + 'user', data);
  }

  //Validar login y generar pin de dos pasos
  user_login(user: UserLogin): Observable<any> {
    let data = {
      user: user.name_user,
      password: user.password
    };

    return this.http.post<UserLogin>(this.apiURL + 'user_login', data);
  }

  //Validar pin de dos pasos
  user_two_step(twoStepData: TwoSteps): Observable<any> {
    return this.http.post(this.apiURL + 'user_two_step', twoStepData);
  }

  //Listar estados
  list_cat_countries_states(id_country: number | null = null): Observable<any> {
    let queryRoute = id_country ? `/${id_country}` : '';
    return this.http.get(this.apiURL + 'list_cat_countries_states' + queryRoute);
  }

  //Listar municipios
  list_cat_states_municipalities(id_state: number | null = null): Observable<any> {
    let queryRoute = id_state ? `/${id_state}` : '';
    return this.http.get<any>(this.apiURL + 'list_cat_states_municipalities' + queryRoute);
  }

  //Listar todos los municipios
  listfullmunicipality(): Observable<any> {
    return this.http.post<any>(this.apiURL + 'listfullmunicipality', {});
  }

  //Listar generos
  list_cat_genders(): Observable<any> {
    return this.http.get(this.apiURL + 'list_cat_genders');
  }

  //Completar datos
  update_person_location(user: UserComplete): Observable<any> {
    let update_data = {
      birth_date: user.date_birth,
      fk_id_cat_gender: user.id_cat_gender,
      mobile_phone: user.phone,
      fk_id_cat_state: user.state,
      fk_id_cat_municipality: user.id_municipio,
      suburb: user.colonia,
      zipcode: user.zip,
      street: user.street,
      ext_number: user.internal_num,
      int_number: user.internal_num
    };

    return this.http.put<UserRegister>(this.apiURL + 'user', update_data, {});
  }

  //Listar instancias
  list_cat_udg_instances(): Observable<any> {
    return this.http.get(this.apiURL + 'list_cat_udg_instances', {});
  }

  //Listar subinstancias
  list_cat_udg_subinstances(id_instance: number | null = null): Observable<any> {
    let queryRoute = id_instance ? `/${id_instance}` : '';
    return this.http.get(this.apiURL + 'list_cat_udg_subinstances' + queryRoute);
  }

  //Listar tipos de miembros en la udg
  list_cat_members_types(): Observable<any> {
    return this.http.get(this.apiURL + 'list_cat_members_types');
  }

  //Listar tipos de pruebas
  list_cat_complaints_evidences_types(): Observable<any> {
    return this.http.get(this.apiURL + 'list_cat_complaints_evidences_types');
  }

  //Listar tipos de medidas cautelares
  list_cat_complaints_precautionaries_measures_types(): Observable<any> {
    return this.http.get(this.apiURL + 'list_cat_complaints_precautionaries_measures_types');
  }

  //Recuperar Contraseña
  user_recovery(email: string): Observable<any> {
    return this.http.get(this.apiURL + 'user_recovery/' + email);
  }

  //Cambiar contraseña - recuperar contraseña
  recovery_password(data: any): Observable<any> {
    return this.http.post(this.apiURL + 'recovery_password', data);
  }

  //Cambiar contraseña dentro del perfil
  change_password(data: any): Observable<any> {
    return this.http.post(this.apiURL + 'change_password', data);
  }

  //Actualizar perfil
  update_profile(data: any): Observable<any> {
    let update_data = {
      names: data.new_user_data.names,
      last_names: data.new_user_data.lastNames,
      birth_date: data.new_user_data.birthday,
      fk_id_cat_gender: data.new_user_data.gender,
      mobile_phone: data.new_user_data.phone,
      fk_id_cat_state: data.new_user_data.state,
      fk_id_cat_municipality: data.new_user_data.municipality,
      suburb: data.new_user_data.colony,
      zipcode: data.new_user_data.zipcode,
      street: data.new_user_data.street,
      ext_number: data.new_user_data.extstreet,
      int_number: data.new_user_data.instreet,
      b64_profile_pic: data.b64_profile_pic
    }

    return this.http.put(this.apiURL + 'user', update_data);
  }

  //Crear denuncia
  create_complaint(complaint: any, evidences: any[] = []): Observable<any> {

    const formData = new FormData();

    formData.append('complaint', JSON.stringify(complaint));

    evidences.forEach((element) => {
      formData.append("evidences[]", element);
    })

    return this.http.post(this.apiURL + 'complaint', formData);
  }

  //Listar denuncias
  list_complaints(): Observable<any> {
    return this.http.get(this.apiURL + 'list_complaints');
  }

  //Unirse a denuncia
  join_complaint(code: any): Observable<any> {
    return this.http.post(this.apiURL + 'join_complaint', code);
  }

  //Listar subinstancias
  get_complaint(id_complaint: number): Observable<any> {
    return this.http.get(this.apiURL + 'complaint/'+id_complaint);
  }

  //Envio de contacto
  SendContact(data: any): Observable<any> {
    return this.http.post(this.apiURL + 'contact_form', data);
  }

  //Consultar primer contacto en la sub instancia
  consult_fisrt_contact(id_subinstance: number): Observable<any> {
    return this.http.get(this.apiURL + 'consult_fisrt_contact?id_subinstancia='+id_subinstance);
  }

  //Agendar cita con el primer contacto
  schedule_appointment(data: any): Observable<any> {
    return this.http.post(this.apiURL + 'schedule_appointment', data);
  }

  //Consultar calendario del usuario
  consult_calendar_user(): Observable<any> {
    return this.http.get(this.apiURL + 'consult_calendar_user');
  }

  test(): Observable<any> {
    return this.http.get(this.apiURL + 'getfile?file=eyJjaXBoZXJ0ZXh0IjoiNENJNG51ZkV5U040alcyOS9jRTNMK3M3RlJ6cE5CbXB4YVhpNHZpQTlXOS9FQmhMNjNRZndyT1NQOWp5U2ZnazU3R3NZNDBuOC9XVU5TckdRZDUybzFlZXM0aytYYjlBMzViT0xwNEY4aE09IiwiaXYiOiI1YzUyY2RmYjk1ZDBkMzVlNTU3NzhjZThjYjgxNzAwOSIsInNhbHQiOiJkNzlmZmE5MjRkYWJlNzY2MGMyN2M0YjRjOGQ3OWJiMzBlOTU1Y2NhNzg5Y2Y5ODRkZGQ2N2JkMWM3MmU1ZWRiZjY2MjQwYTc2MjRjZjZlMjAxZTYzNmM3ZThjZTUzZmE2MWU4MTAwMzkzYTgxODNhMTQ2N2E5YWJiMjBhNjA4OTE5Njc2NzY5ZjBjZTc2ZTE5YjQwNzdkNGEyOGM2MjQ3NmVjNGUyYzAyNDliMjIwNDUwMTU2Y2U0Mjk5YTFkMzM2NmRkNTNlNTQxNGNmOTMxYzI3NTkyZDdhMTQ5NWViY2Q3MzFkMWY3Y2Q3MmIwZDViNDk0M2NjNWQ4ODYzYjcxM2JjMDkxNjk0MDVhZTVlNmRhMDg4OGMwZmRlNTllNjZhM2VlODRmNmI5YTBkMTJkNzMxNjU4YWE4ODUyN2YxYTJlMTc5ZTM1NTYzZGYzZDcwYmIyNDdmZjMzMTk3ZjQ3NWJlOTJhMjQ5MTA2NTJjNDE1ZTMwMTE1NjcwNGE5ZDlkOTE3MWEyZjdkOGM5NmQ5ODUzOTk5ZDYwYzkyNDM2OGM3NTJjMzRjNDRhZGQwYWVkOGE5ZmE0Y2FjMDUxY2Q4NzU2MTAzOTFlMzhmNWUyZDZhMGFkNDg1M2ZhY2ExNTE1NjVjMzY2N2RjOTk2MWU4YTRlOGM0Njc3NDdmNDRiNyIsIml0ZXJhdGlvbnMiOjk5OX0=');
  }
}