// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

export const environment = {
  production: false,
  title: 'Surtech UDG Control de Calidad',
  apiURL: 'https://qa.servicioslibres.com/application/api/',
  RECAPTCHA_SITE_KEY: "6LdpVkwfAAAAAKAHZ34iMzccZUlVBywjqPpnGRzM",
  RECAPTCHA_SECRET_KEY: "6LdpVkwfAAAAAEPAbH6MNusBJZH5F4gA-tRbTH4Y"
};
