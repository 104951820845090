import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RoutesRecognized } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import { JsEncode } from '../core/exports/JsEncode';
import { AccessToken, UserData } from '../core/interfaces/user-data';
import { SaveToken, UserDataSave } from '../core/reducer/user-data/user-data.actions';
import { AccessTokenSelector, UserDataFull } from '../core/reducer/user-data/user-data.selector';
import FingerprintJS from "@fingerprintjs/fingerprintjs";

@Component({
  selector: 'app-root',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss'],
  host: { 'class': 'flex-1 flex' }
})
export class BaseComponent {

  @ViewChild('mobileMenu') mobileMenu!: ElementRef;

  title = 'surtechudg_front';
  loggedIn: boolean = false;

  UserData$?: Observable<any>;
  UserDataFull: UserData = {} as UserData;

  Token$?: Observable<any>;
  DataToken: AccessToken = {} as AccessToken;
  private _unsubscribe = new Subject<void>();


  constructor(private router: Router, private store: Store, private http: HttpClient) {

    let user_data: string = (localStorage.getItem('user_data') as any);
    if (user_data) {

      let data_user: UserData = JSON.parse(JsEncode.decrypt(user_data))

      this.store.dispatch(new UserDataSave({
        UserData: data_user as UserData
      }))

      this.UserData$ = this.store.pipe(select(UserDataFull))

      this.UserData$.pipe(takeUntil(this._unsubscribe)).subscribe(
        data => {
          this.UserDataFull = data;
        }
      )
    }

    let token: string = (localStorage.getItem('x_access_token') as any);
    if (token) {

      let AccessToken: AccessToken = {
        token: token,
        DataToken: JSON.parse(JsEncode.decrypt(token))
      }

      this.store.dispatch(new SaveToken({
        AccessToken: AccessToken as AccessToken
      }))

      this.Token$ = this.store.pipe(select(AccessTokenSelector))

      this.Token$.pipe(takeUntil(this._unsubscribe)).subscribe(
        data => {
          this.DataToken = data;
        }
      )
    }

    router.events.pipe(takeUntil(this._unsubscribe)).subscribe((event) => {
      if (event instanceof NavigationStart) { }

      if (event instanceof NavigationEnd) { }

      if (event instanceof NavigationCancel) { }

      if (event instanceof NavigationError) { }

      if (event instanceof RoutesRecognized) { }
    });

    FingerprintJS.load().then((fp: any) => fp.get()).then((result: any) => {
      localStorage.setItem('fingerprint', result.visitorId);
    });
  }
}
