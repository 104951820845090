import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'ucFirst',
})
export class UcFirstPipe implements PipeTransform {
	constructor() {}
	transform(value: string): unknown {
		return value ? value.charAt(0).toUpperCase() + value.slice(1).toLowerCase() : value;
	}
}
