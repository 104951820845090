import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanDeactivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject, takeUntil } from 'rxjs';
import Swal from 'sweetalert2';
import { JsEncode } from '../exports/JsEncode';
import { surtechUtils } from '../exports/surtechUtils';
import { AccessToken, UserData } from '../interfaces/user-data';
import { Logout, SaveToken, UserDataSave } from '../reducer/user-data/user-data.actions';
import { AccessTokenSelector, UserDataFull } from '../reducer/user-data/user-data.selector';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {
  Token$?: Observable<any>;
  DataToken: AccessToken = {} as AccessToken;

  UserData$?: Observable<any>;
  UserDataFull: UserData = {} as UserData;
  private _unsubscribe = new Subject<void>();

  private userData: string | null = null;
  private tokenData: string | null = null;
  private token: string | null = null;


  constructor(private store: Store, private router: Router, private toastr: ToastrService) {

    this.checkAndUpdateDataLogic();

    // Si no son de tipo null
    if (this.userData && this.tokenData) {

      this.store.dispatch(new UserDataSave({
        UserData: (this.userData as any) as UserData
      }))

      let AccessToken: AccessToken = {
        token: this.token || '',
        DataToken: (this.tokenData as any)
      }

      this.store.dispatch(new SaveToken({
        AccessToken: AccessToken as AccessToken
      }))

      this.Token$ = this.store.pipe(select(AccessTokenSelector))

      this.Token$.pipe(takeUntil(this._unsubscribe)).subscribe(
        data => {
          this.DataToken = data;
        }
      )
      
      this.UserData$ = this.store.pipe(select(UserDataFull))

      this.UserData$.pipe(takeUntil(this._unsubscribe)).subscribe(
        data => {
          this.UserDataFull = data;
        }
      )

    }
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    this.checkAndUpdateDataLogic();

    //Si el token y la data del usuario no estan vacios
    if (this.tokenData && this.userData) {

      //Experimental
      let tokenType = this.token?.includes('eyJjaXBoZXJ0ZXh0Ijoi');
      if (!tokenType) alert('formato de token distinto al esperado, porfavor revisar el authguard y el token. Este mensaje es experimental para el area de frontend. No se trata de un error.')

      //Se calcula la duración de la sesión

      let momentDate = moment(this.DataToken.DataToken.time).add(this.DataToken.DataToken.seconds, 'seconds');
      let validSession = moment().isBefore(momentDate);

      //Si la sesión aun es valida
      if (validSession) {

        //Si la ruta es complete-data
        if (route.data['url'] == 'complete-data') {

          //Si el usuario aun no ha completado sus datos
          if (!this.UserDataFull.birth_date) {
            return true;
          } else {
            this.router.navigateByUrl('/dashboard');
            return false;
          }

        } else {

          //Si el usuario ya completo sus datos
          if (this.UserDataFull.street) {
            return true;
          } else {
            this.toastr.warning(surtechUtils.statusMessages('advertencia-completar-datos'))
            this.router.navigateByUrl('/complete-data');
            return false;

          }

        }

      } else {
        return this.sessionExpired();
      }
    } else {
      return this.sessionExpired();
    }
  }

  private checkAndUpdateDataLogic() {
    // Obtenemos el string del token y de la data del usuario
    let data_user = (localStorage.getItem('user_data') as any) || null;
    let data_token = (localStorage.getItem('x_access_token') as any) || null;

    // validamos que no sean null y que la longitud sea mayor de 500 caracteres (usualmente son superiores a 1000)
    if (data_user && data_token) if ((data_user.length > 500) && (data_token.length > 500)) if (this.token != data_token) {
      try {
        // lo almacenamos en las variables privadas
        this.userData = JSON.parse(JsEncode.decrypt(data_user)) || null;
        this.tokenData = JSON.parse(JsEncode.decrypt(data_token)) || null
        this.token = data_token;
      } catch (e) {
        this.userData = null;
        this.tokenData = null;
      }
    }
  }

  private sessionExpired(): boolean {

    this.store.dispatch(new Logout());
    this.router.navigateByUrl('/home/login');
    this.toastr.error(surtechUtils.statusMessages('error-sesion-inactiva'))
    return false;

  }
}
