<div *ngIf="complaintData && type == 'resume'" class="complaintResume">
	<!-- <div class="flex flex-col sm:flex-row items-left">
		<h2 class="font-medium text-base">Denuncia</h2>
		<div *ngIf="canExport" class="w-full sm:w-auto sm:ml-auto mt-3 sm:mt-0">
			<a class="btn btn-primary">Exportar Documento</a>
		</div>
	</div>-->

	<h2 class="font-medium text-base">Denunciante</h2>

	<table class="table table-bordered mt-5">
		<thead class="table-light">
			<tr>
				<th colspan="2">Datos del Denunciante</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<td colspan="2" style="width: 50%">
					<b>Nombre:</b>
					<br />
					<span>{{ complaintData.whistleblower.names }} {{ complaintData.whistleblower.lastNames }}</span>
				</td>
			</tr>
			<tr>
				<td style="width: 50%">
					<b>Fecha de Nacimiento:</b>
					<br />
					<span>{{ complaintData.whistleblower.birthday ? (complaintData.whistleblower.birthday | mask: '00-00-0000') : '---' }}</span>
				</td>
				<td style="width: 50%">
					<b>Género:</b>
					<br />
					<span>{{ complaintData.whistleblower.gender ? getListString(complaintData.whistleblower.gender, lists.genderList) : '---' }}</span>
				</td>
			</tr>
			<tr>
				<td style="width: 50%">
					<b>Teléfono:</b>
					<br />
					<span>{{ complaintData.whistleblower.phone ? (complaintData.whistleblower.phone | mask: '(00) 0000 0000') : '---' }}</span>
				</td>
				<td style="width: 50%">
					<b>Correo Electrónico:</b>
					<br />
					<span>{{ complaintData.whistleblower.email ? complaintData.whistleblower.email : '---' }}</span>
				</td>
			</tr>
			<tr>
				<td>
					<b>Notificación Mediante Correo Electrónico:</b>
					<br />
					<span>{{ complaintData.whistleblower.mailNotification ? 'Si' : 'No' }}</span>
				</td>
				<td>
					<b>Firma del Denunciante:</b>
					<img src="{{ complaintData.b64signature }}" />
				</td>
			</tr>
			<tr>
				<td style="width: 50%">
					<b>Pertenece a un Grupo de Vulnerabilidad:</b>
					<br />
					<span>{{ complaintData.whistleblower.vulnerabilityGroup ? 'Si' : 'No' }}</span>
				</td>
				<td style="width: 50%">
					<b>Se Encuentra Fuera de México:</b>
					<br />
					<span>{{ complaintData.whistleblower.outMexico ? 'Si' : 'No' }}</span>
				</td>
			</tr>
			<tr *ngIf="complaintData.whistleblower.outMexico">
				<td colspan="2" style="width: 50%">
					<b>Localidad Actual:</b>
					<br />
					<span>{{ complaintData.whistleblower.location ? complaintData.whistleblower.location : '---' }}</span>
				</td>
			</tr>
			<tr *ngIf="!complaintData.whistleblower.outMexico">
				<td style="width: 50%">
					<b>Estado:</b>
					<br />
					<span>{{ complaintData.whistleblower.state ? getListString(complaintData.whistleblower.state, lists.statesList) : '---' }}</span>
				</td>
				<td style="width: 50%">
					<b>Municipio:</b>
					<br />
					<span>{{ complaintData.whistleblower.municipality ? getListString(complaintData.whistleblower.municipality, lists.municipalityList) : '---' }}</span>
				</td>
			</tr>
			<tr *ngIf="!complaintData.whistleblower.outMexico">
				<td style="width: 50%">
					<b>Colonia:</b>
					<br />
					<span>{{ complaintData.whistleblower.colony ? complaintData.whistleblower.colony : '---' }}</span>
				</td>
				<td style="width: 50%">
					<b>Código Postal:</b>
					<br />
					<span>{{ complaintData.whistleblower.zipcode ? complaintData.whistleblower.zipcode : '---' }}</span>
				</td>
			</tr>
			<tr *ngIf="!complaintData.whistleblower.outMexico">
				<td colspan="2">
					<b>Calle:</b>
					<br />
					<span>{{ complaintData.whistleblower.street ? complaintData.whistleblower.street : '---' }}</span>
				</td>
			</tr>
			<tr *ngIf="!complaintData.whistleblower.outMexico">
				<td style="width: 50%">
					<b>No. Exterior:</b>
					<br />
					<span>{{ complaintData.whistleblower.extstreet ? complaintData.whistleblower.extstreet : '---' }}</span>
				</td>
				<td style="width: 50%">
					<b>No. Interior:</b>
					<br />
					<span>{{ complaintData.whistleblower.instreet ? complaintData.whistleblower.instreet : '---' }}</span>
				</td>
			</tr>
			<tr>
				<td style="width: 50%">
					<b>El Denunciante Forma Parte Del:</b>
					<br />
					<span>{{ complaintData.whistleblower.participantRole ? getListString(complaintData.whistleblower.participantRole, lists.participantRoleList) : '---' }}</span>
				</td>
				<td style="width: 50%">
					<b>Otro Tipo de Personal:</b>
					<br />
					<span>{{ complaintData.whistleblower.participantRoleOthers ? complaintData.whistleblower.participantRoleOthers : '---' }}</span>
				</td>
			</tr>
			<tr>
				<td style="width: 50%">
					<b>Instancia de Adscripción/Inscripción:</b>
					<br />
					<span>{{ complaintData.whistleblower.instance ? getListString(complaintData.whistleblower.instance, lists.instanceList) : '---' }}</span>
				</td>
				<td style="width: 50%">
					<b>Escuela/Dependencia:</b>
					<br />
					<span>{{ complaintData.whistleblower.entity ? getListString(complaintData.whistleblower.entity, lists.entityList) : '---' }}</span>
				</td>
			</tr>
			<tr>
				<td colspan="2">
					<b>Características Adicionales del Individuo:</b>
					<br />
					<span>{{ complaintData.whistleblower.characteristics ? complaintData.whistleblower.characteristics : '---' }}</span>
				</td>
			</tr>
		</tbody>
	</table>

	<h2 *ngIf="complaintData.victims" class="font-medium text-base mt-5">Víctima(s)</h2>

	<table *ngFor="let participant of complaintData.victims; let i = index" class="table table-bordered mt-5">
		<thead class="table-light">
			<tr>
				<th colspan="2">Datos de la Víctima #{{ i + 1 }}</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<td colspan="2" style="width: 50%">
					<b>Nombre:</b>
					<br />
					<span>{{ participant.names }} {{ participant.lastNames }}</span>
				</td>
			</tr>
			<tr>
				<td style="width: 50%">
					<b>Fecha de Nacimiento:</b>
					<br />
					<span>{{ participant.birthday ? (participant.birthday | mask: '00-00-0000') : '---' }}</span>
				</td>
				<td style="width: 50%">
					<b>Género:</b>
					<br />
					<span>{{ participant.gender ? getListString(participant.gender, lists.genderList) : '---' }}</span>
				</td>
			</tr>
			<tr>
				<td style="width: 50%">
					<b>Teléfono:</b>
					<br />
					<span>{{ participant.phone ? (participant.phone | mask: '(00) 0000 0000') : '---' }}</span>
				</td>
				<td style="width: 50%">
					<b>Correo Electrónico:</b>
					<br />
					<span>{{ participant.email ? participant.email : '---' }}</span>
				</td>
			</tr>
			<tr>
				<td>
					<b>Notificación Mediante Correo Electrónico</b>
					<br />
					<span>{{ participant.mailNotification ? 'Si' : 'No' }}</span>
				</td>
				<td></td>
			</tr>
			<tr>
				<td style="width: 50%">
					<b>Pertenece a un Grupo de Vulnerabilidad:</b>
					<br />
					<span>{{ participant.vulnerabilityGroup ? 'Si' : 'No' }}</span>
				</td>
				<td style="width: 50%">
					<b>Se Encuentra Fuera de México:</b>
					<br />
					<span>{{ participant.outMexico ? 'Si' : 'No' }}</span>
				</td>
			</tr>
			<tr *ngIf="participant.outMexico">
				<td colspan="2" style="width: 50%">
					<b>Localidad Actual:</b>
					<br />
					<span>{{ participant.location ? participant.location : '---' }}</span>
				</td>
			</tr>
			<tr *ngIf="!participant.outMexico">
				<td style="width: 50%">
					<b>Estado:</b>
					<br />
					<span>{{ participant.state ? getListString(participant.state, lists.statesList) : '---' }}</span>
				</td>
				<td style="width: 50%">
					<b>Municipio:</b>
					<br />
					<span>{{ participant.municipality ? getListString(participant.municipality, lists.municipalityList) : '---' }}</span>
				</td>
			</tr>
			<tr *ngIf="!participant.outMexico">
				<td style="width: 50%">
					<b>Colonia:</b>
					<br />
					<span>{{ participant.colony ? participant.colony : '---' }}</span>
				</td>
				<td style="width: 50%">
					<b>Código Postal:</b>
					<br />
					<span>{{ participant.zipcode ? participant.zipcode : '---' }}</span>
				</td>
			</tr>
			<tr *ngIf="!participant.outMexico">
				<td colspan="2">
					<b>Calle:</b>
					<br />
					<span>{{ participant.street ? participant.street : '---' }}</span>
				</td>
			</tr>
			<tr *ngIf="!participant.outMexico">
				<td style="width: 50%">
					<b>No. Exterior:</b>
					<br />
					<span>{{ participant.extstreet ? participant.extstreet : '---' }}</span>
				</td>
				<td style="width: 50%">
					<b>No. Interior:</b>
					<br />
					<span>{{ participant.instreet ? participant.instreet : '---' }}</span>
				</td>
			</tr>
			<tr>
				<td style="width: 50%">
					<b>La Víctima Forma Parte Del:</b>
					<br />
					<span>{{ participant.participantRole ? getListString(participant.participantRole, lists.participantRoleList) : '---' }}</span>
				</td>
				<td style="width: 50%">
					<b>Otro Tipo de Personal:</b>
					<br />
					<span>{{ participant.participantRoleOthers ? participant.participantRoleOthers : '---' }}</span>
				</td>
			</tr>
			<tr>
				<td style="width: 50%">
					<b>Instancia de Adscripción/Inscripción:</b>
					<br />
					<span>{{ participant.instance ? getListString(participant.instance, lists.instanceList) : '---' }}</span>
				</td>
				<td style="width: 50%">
					<b>Escuela/Dependencia:</b>
					<br />
					<span>{{ participant.entity ? getListString(participant.entity, lists.entityList) : '---' }}</span>
				</td>
			</tr>
			<tr>
				<td colspan="2">
					<b>Características Adicionales del Individuo:</b>
					<br />
					<span>{{ participant.characteristics ? participant.characteristics : '---' }}</span>
				</td>
			</tr>
		</tbody>
		<ng-container *ngFor="let parent of participant.parents; let i = index">
			<thead class="table-light">
				<tr>
					<th colspan="2">Tutor #1 de la Víctima #{{ i + 1 }}</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td colspan="2" style="width: 50%">
						<b>Nombre:</b>
						<br />
						<span>{{ parent.names || parent.lastNames ? parent.names + ' ' + parent.lastNames : '---' }}</span>
					</td>
				</tr>
				<tr>
					<td colspan="2" style="width: 50%">
						<b>Correo Electrónico:</b>
						<br />
						<span>{{ parent.email ? parent.email : '---' }}</span>
					</td>
				</tr>
				<tr>
					<td style="width: 50%">
						<b>Estado:</b>
						<br />
						<span>{{ parent.state ? getListString(parent.state, lists.statesList) : '---' }}</span>
					</td>
					<td style="width: 50%">
						<b>Municipio:</b>
						<br />
						<span>{{ parent.municipality ? getListString(parent.municipality, lists.municipalityList) : '---' }}</span>
					</td>
				</tr>
				<tr>
					<td style="width: 50%">
						<b>Colonia:</b>
						<br />
						<span>{{ parent.colony ? parent.colony : '---' }}</span>
					</td>
					<td style="width: 50%">
						<b>Código Postal:</b>
						<br />
						<span>{{ parent.zipcode ? parent.zipcode : '---' }}</span>
					</td>
				</tr>
				<tr>
					<td colspan="2">
						<b>Calle:</b>
						<br />
						<span>{{ parent.street ? parent.street : '---' }}</span>
					</td>
				</tr>
				<tr>
					<td style="width: 50%">
						<b>No. Exterior:</b>
						<br />
						<span>{{ parent.extstreet ? parent.extstreet : '---' }}</span>
					</td>
					<td style="width: 50%">
						<b>No. Interior:</b>
						<br />
						<span>{{ parent.instreet ? parent.instreet : '---' }}</span>
					</td>
				</tr>
			</tbody>
		</ng-container>
	</table>

	<h2 class="font-medium text-base mt-5">Persona(s) Señalada(s) de Responsables</h2>

	<table *ngFor="let participant of complaintData.responsibles; let i = index" class="table table-bordered mt-5">
		<thead class="table-light">
			<tr>
				<th colspan="2">Datos de la Persona Señalada de Responsable #{{ i + 1 }}</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<td colspan="2" style="width: 50%">
					<b>Nombre:</b>
					<br />
					<span>{{ participant.names }} {{ participant.lastNames }}</span>
				</td>
			</tr>
			<tr>
				<td style="width: 50%">
					<b>Fecha de Nacimiento:</b>
					<br />
					<span>{{ participant.birthday ? (participant.birthday | mask: '00-00-0000') : '---' }}</span>
				</td>
				<td style="width: 50%">
					<b>Género:</b>
					<br />
					<span>{{ participant.gender ? getListString(participant.gender, lists.genderList) : '---' }}</span>
				</td>
			</tr>
			<tr>
				<td style="width: 50%">
					<b>Teléfono:</b>
					<br />
					<span>{{ participant.phone ? (participant.phone | mask: '(00) 0000 0000') : '---' }}</span>
				</td>
				<td style="width: 50%">
					<b>Correo Electrónico:</b>
					<br />
					<span>{{ participant.email ? participant.email : '---' }}</span>
				</td>
			</tr>
			<tr>
				<td>
					<b>Notificación Mediante Correo Electrónico</b>
					<br />
					<span>{{ participant.mailNotification ? 'Si' : 'No' }}</span>
				</td>
				<td></td>
			</tr>
			<tr>
				<td style="width: 50%">
					<b>Pertenece a un Grupo de Vulnerabilidad:</b>
					<br />
					<span>{{ participant.vulnerabilityGroup ? 'Si' : 'No' }}</span>
				</td>
				<td style="width: 50%">
					<b>Se Encuentra Fuera de México:</b>
					<br />
					<span>{{ participant.outMexico ? 'Si' : 'No' }}</span>
				</td>
			</tr>
			<tr *ngIf="participant.outMexico">
				<td colspan="2" style="width: 50%">
					<b>Localidad Actual:</b>
					<br />
					<span>{{ participant.location ? participant.location : '---' }}</span>
				</td>
			</tr>
			<tr *ngIf="!participant.outMexico">
				<td style="width: 50%">
					<b>Estado:</b>
					<br />
					<span>{{ participant.state ? getListString(participant.state, lists.statesList) : '---' }}</span>
				</td>
				<td style="width: 50%">
					<b>Municipio:</b>
					<br />
					<span>{{ participant.municipality ? getListString(participant.municipality, lists.municipalityList) : '---' }}</span>
				</td>
			</tr>
			<tr *ngIf="!participant.outMexico">
				<td style="width: 50%">
					<b>Colonia:</b>
					<br />
					<span>{{ participant.colony ? participant.colony : '---' }}</span>
				</td>
				<td style="width: 50%">
					<b>Código Postal:</b>
					<br />
					<span>{{ participant.zipcode ? participant.zipcode : '---' }}</span>
				</td>
			</tr>
			<tr *ngIf="!participant.outMexico">
				<td colspan="2">
					<b>Calle:</b>
					<br />
					<span>{{ participant.street ? participant.street : '---' }}</span>
				</td>
			</tr>
			<tr *ngIf="!participant.outMexico">
				<td style="width: 50%">
					<b>No. Exterior:</b>
					<br />
					<span>{{ participant.extstreet ? participant.extstreet : '---' }}</span>
				</td>
				<td style="width: 50%">
					<b>No. Interior:</b>
					<br />
					<span>{{ participant.instreet ? participant.instreet : '---' }}</span>
				</td>
			</tr>
			<tr>
				<td style="width: 50%">
					<b>La Persona Señalada de Responable Forma Parte Del:</b>
					<br />
					<span>{{ participant.participantRole ? getListString(participant.participantRole, lists.participantRoleList) : '---' }}</span>
				</td>
				<td style="width: 50%">
					<b>Otro Tipo de Personal:</b>
					<br />
					<span>{{ participant.participantRoleOthers ? participant.participantRoleOthers : '---' }}</span>
				</td>
			</tr>
			<tr>
				<td style="width: 50%">
					<b>Instancia de Adscripción/Inscripción:</b>
					<br />
					<span>{{ participant.instance ? getListString(participant.instance, lists.instanceList) : '---' }}</span>
				</td>
				<td style="width: 50%">
					<b>Escuela/Dependencia:</b>
					<br />
					<span>{{ participant.entity ? getListString(participant.entity, lists.entityList) : '---' }}</span>
				</td>
			</tr>
			<tr>
				<td colspan="2">
					<b>Características Adicionales del Individuo:</b>
					<br />
					<span>{{ participant.characteristics ? participant.characteristics : '---' }}</span>
				</td>
			</tr>
		</tbody>
		<ng-container *ngFor="let parent of participant.parents; let i = index">
			<thead class="table-light">
				<tr>
					<th colspan="2">Tutor #1 del la Persona Señalada de Responsable #{{ i + 1 }}</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td colspan="2" style="width: 50%">
						<b>Nombre:</b>
						<br />
						<span>{{ parent.names || parent.lastNames ? parent.names + ' ' + parent.lastNames : '---' }}</span>
					</td>
				</tr>
				<tr>
					<td colspan="2" style="width: 50%">
						<b>Correo Electrónico:</b>
						<br />
						<span>{{ parent.email ? parent.email : '---' }}</span>
					</td>
				</tr>
				<tr>
					<td style="width: 50%">
						<b>Estado:</b>
						<br />
						<span>{{ parent.state ? getListString(parent.state, lists.statesList) : '---' }}</span>
					</td>
					<td style="width: 50%">
						<b>Municipio:</b>
						<br />
						<span>{{ parent.municipality ? getListString(parent.municipality, lists.municipalityList) : '---' }}</span>
					</td>
				</tr>
				<tr>
					<td style="width: 50%">
						<b>Colonia:</b>
						<br />
						<span>{{ parent.colony ? parent.colony : '---' }}</span>
					</td>
					<td style="width: 50%">
						<b>Código Postal:</b>
						<br />
						<span>{{ parent.zipcode ? parent.zipcode : '---' }}</span>
					</td>
				</tr>
				<tr>
					<td colspan="2">
						<b>Calle:</b>
						<br />
						<span>{{ parent.street ? parent.street : '---' }}</span>
					</td>
				</tr>
				<tr>
					<td style="width: 50%">
						<b>No. Exterior:</b>
						<br />
						<span>{{ parent.extstreet ? parent.extstreet : '---' }}</span>
					</td>
					<td style="width: 50%">
						<b>No. Interior:</b>
						<br />
						<span>{{ parent.instreet ? parent.instreet : '---' }}</span>
					</td>
				</tr>
			</tbody>
		</ng-container>
	</table>

	<h2 class="font-medium text-base mt-5">Denuncia</h2>

	<table class="table table-bordered mt-5">
		<thead class="table-light">
			<tr>
				<th colspan="2">Datos de la Denuncia</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<td style="width: 50%">
					<b>Código:</b>
					<br />
					<span>{{ complaintData.code ? complaintData.code : 'Se asignará una vez generada la denuncia' }}</span>
				</td>
				<td style="width: 50%">
					<b>Fecha:</b>
					<br />
					<span>{{ complaintData.date ? complaintData.date : (nowDate | date: 'dd-MM-YYYY') }}</span>
				</td>
			</tr>
			<tr>
				<td style="width: 50%">
					<b>Estado:</b>
					<br />
					<span>{{ complaintData.state ? getListString(complaintData.state, lists.statesList) : complaintData.whistleblower.state ? getListString(complaintData.whistleblower.state, lists.statesList) : '---' }}</span>
				</td>
				<td style="width: 50%">
					<b>Municipio:</b>
					<br />
					<span>{{ complaintData.municipality ? getListString(complaintData.municipality, lists.municipalityList) : complaintData.whistleblower.municipality ? getListString(complaintData.whistleblower.municipality, lists.municipalityList) : '---' }}</span>
				</td>
			</tr>
			<tr>
				<td style="width: 50%">
					<b>Existencia de Víctimas o Actos de Violencia:</b>
					<br />
					<span>{{ complaintData.haveVictims ? 'Si' : 'No' }}</span>
				</td>
				<td style="width: 50%">
					<b>Notificación Mediante Correo Electrónico:</b>
					<br />
					<span>Se notificará a los participantes que lo indiquen</span>
				</td>
			</tr>
			<tr>
				<td colspan="2">
					<b>Descripción de los Hechos:</b>
					<br />
					<span>{{ complaintData.acts ? complaintData.acts : '---' }}</span>
				</td>
			</tr>
			<tr>
				<td colspan="2">
					<b>Observaciones:</b>
					<br />
					<span>{{ complaintData.observations ? complaintData.observations : '---' }}</span>
				</td>
			</tr>
			<tr>
				<td>
					<b>Medidas Cautelares Solicitadas:</b>
					<br />
					<ul>
						<li *ngFor="let measure of complaintData.measures; let i = index">
							<span>- {{ getListString(measure.id, lists.measuresList) | ucFirst }}</span>
						</li>
					</ul>
				</td>
				<td>
					<b>Justificación de las Medidas Cautelares Solicitadas:</b>
					<br />
					<span>{{ complaintData.measuresJustification ? complaintData.measuresJustification : '---' }}</span>
				</td>
			</tr>
			<tr>
				<td>
					<b>Pruebas Presentadas:</b>
					<br />
					<ul>
						<li *ngFor="let evidence of complaintData.evidencesTypes; let i = index">
							<span>- {{ evidence.text }}</span>
						</li>
					</ul>
				</td>
				<td style="border-right: none !important; padding: 0 !important">
					<table>
						<thead class="table-light">
							<tr>
								<td style="width: 100%; border-top: none !important; border-left: none !important">
									<b>Nombre de la Prueba</b>
								</td>
								<td style="min-width: 135px; border-top: none !important">
									<b>Tipo</b>
								</td>
								<td style="min-width: 135px; border-top: none !important">
									<b>Peso</b>
								</td>
							</tr>
						</thead>
						<tbody *ngIf="!complaintData.evidencesNames">
							<tr>
								<td colspan="3" style="border-bottom: none !important; border-left: none !important">
									<span>No se cargaron medios de prueba</span>
								</td>
							</tr>
						</tbody>
						<tbody *ngIf="complaintData.evidencesNames">
							<tr *ngFor="let evidence of complaintData.evidencesNames; let i = index">
								<td style="border-bottom: none !important; border-left: none !important">
									<span>{{ evidence.name }}</span>
								</td>
								<td style="border-bottom: none !important">
									<span>{{ getCleanFileType(evidence.name) }}</span>
								</td>
								<td style="border-bottom: none !important">
									<span>{{ getReadableFileSize(evidence.size, evidence.transform != undefined ? evidence.transform : true) }}</span>
								</td>
							</tr>
						</tbody>
					</table>
				</td>
			</tr>
		</tbody>
	</table>

	<h2 *ngIf="complaintData.witnesses" class="font-medium text-base mt-5">Testigo(s)</h2>

	<table *ngFor="let participant of complaintData.witnesses; let i = index" class="table table-bordered mt-5">
		<thead class="table-light">
			<tr>
				<th colspan="2">Datos del Testigo #{{ i + 1 }}</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<td colspan="2" style="width: 50%">
					<b>Nombre:</b>
					<br />
					<span>{{ participant.names }} {{ participant.lastNames }}</span>
				</td>
			</tr>
			<tr>
				<td style="width: 50%">
					<b>Teléfono:</b>
					<br />
					<span>{{ participant.phone ? (participant.phone | mask: '(00) 0000 0000') : '---' }}</span>
				</td>
				<td style="width: 50%">
					<b>Correo Electrónico:</b>
					<br />
					<span>{{ participant.email ? participant.email : '---' }}</span>
				</td>
			</tr>
			<tr>
				<td style="width: 50%">
					<b>Instancia de Adscripción/Inscripción:</b>
					<br />
					<span>{{ participant.instance ? getListString(participant.instance, lists.instanceList) : '---' }}</span>
				</td>
				<td style="width: 50%">
					<b>Escuela/Dependencia:</b>
					<br />
					<span>{{ participant.entity ? getListString(participant.entity, lists.entityList) : '---' }}</span>
				</td>
			</tr>
			<tr>
				<td colspan="2">
					<b>Características Adicionales del Individuo:</b>
					<br />
					<span>{{ participant.characteristics ? (participant.characteristics | ucFirst) : '---' }}</span>
				</td>
			</tr>
		</tbody>
	</table>
</div>

<div *ngIf="complaintData && type == 'constancy'" class="complaintResume">
	<div class="flex flex-col sm:flex-row items-left">
		<h2 class="font-medium text-base">Denuncia</h2>
		<div *ngIf="canExport" class="w-full sm:w-auto sm:ml-auto mt-3 sm:mt-0">
			<a class="btn btn-primary">Exportar Documento</a>
		</div>
	</div>

	<table class="table table-bordered mt-5">
		<thead class="table-light">
			<tr>
				<th colspan="2">Datos de la Denuncia</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<td style="width: 50%">
					<b>Código:</b>
					<br />
					<span>{{ complaintData.code ? complaintData.code : 'Se asignará una vez generada la denuncia' }}</span>
				</td>
				<td style="width: 50%">
					<b>Fecha:</b>
					<br />
					<span>{{ complaintData.date ? complaintData.date : (nowDate | date: 'dd-MM-YYYY') }}</span>
				</td>
			</tr>
		</tbody>
	</table>

	<h2 class="font-medium text-base mt-5">Primer Contacto</h2>

	<table class="table table-bordered mt-5">
		<thead class="table-light">
			<tr>
				<th colspan="2">Datos del Primer Contacto</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<td colspan="2" style="width: 50%">
					<b>Nombre:</b>
					<br />
					<span>{{ complaintData.firtContact.names }} {{ complaintData.firtContact.lastNames }}</span>
				</td>
			</tr>
			<tr>
				<td style="width: 50%">
					<b>Instancia de Adscripción/Inscripción:</b>
					<br />
					<span>{{ complaintData.firtContact.instance ? getListString(complaintData.firtContact.instance, lists.instanceList) : '---' }}</span>
				</td>
				<td style="width: 50%">
					<b>Domicilio de la instancia de adscripción:</b>
					<br />
					<span>{{ complaintData.firtContact.instanceAddress ? (complaintData.firtContact.instanceAddress | titlecase) : '---' }}</span>
				</td>
			</tr>
			<tr>
				<td style="width: 50%">
					<b>Teléfono:</b>
					<br />
					<span>{{ complaintData.firtContact.phone ? (complaintData.firtContact.phone | mask: '(00) 0000 0000') : '---' }}</span>
				</td>
				<td style="width: 50%">
					<b>Correo Electrónico:</b>
					<br />
					<span>{{ complaintData.firtContact.email ? complaintData.firtContact.email : '---' }}</span>
				</td>
			</tr>
			<tr>
				<td style="width: 50%">
					<b>Estado:</b>
					<br />
					<span>{{ complaintData.firtContact.state ? getListString(complaintData.firtContact.state, lists.statesList) : '---' }}</span>
				</td>
				<td style="width: 50%">
					<b>Municipio:</b>
					<br />
					<span>{{ complaintData.firtContact.municipality ? getListString(complaintData.firtContact.municipality, lists.municipalityList) : '---' }}</span>
				</td>
			</tr>
			<tr>
				<td style="width: 50%">
					<b>Colonia:</b>
					<br />
					<span>{{ complaintData.firtContact.colony ? complaintData.firtContact.colony : '---' }}</span>
				</td>
				<td style="width: 50%">
					<b>Código Postal:</b>
					<br />
					<span>{{ complaintData.firtContact.zipcode ? complaintData.firtContact.zipcode : '---' }}</span>
				</td>
			</tr>
			<tr>
				<td colspan="2">
					<b>Calle:</b>
					<br />
					<span>{{ complaintData.firtContact.street ? complaintData.firtContact.street : '---' }}</span>
				</td>
			</tr>
			<tr>
				<td style="width: 50%">
					<b>No. Exterior:</b>
					<br />
					<span>{{ complaintData.firtContact.extstreet ? complaintData.firtContact.extstreet : '---' }}</span>
				</td>
				<td style="width: 50%">
					<b>No. Interior:</b>
					<br />
					<span>{{ complaintData.firtContact.instreet ? complaintData.firtContact.instreet : '---' }}</span>
				</td>
			</tr>
		</tbody>
	</table>

	<h2 class="font-medium text-base mt-5">Denunciante</h2>

	<table class="table table-bordered mt-5">
		<thead class="table-light">
			<tr>
				<th colspan="2">Datos del Denunciante</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<td colspan="2" style="width: 50%">
					<b>Nombre:</b>
					<br />
					<span>{{ complaintData.whistleblower.names }} {{ complaintData.whistleblower.lastNames }}</span>
				</td>
			</tr>
			<tr>
				<td style="width: 50%">
					<b>Fecha de Nacimiento:</b>
					<br />
					<span>{{ complaintData.whistleblower.birthday ? (complaintData.whistleblower.birthday | mask: '00-00-0000') : '---' }}</span>
				</td>
				<td style="width: 50%">
					<b>Género:</b>
					<br />
					<span>{{ complaintData.whistleblower.gender ? getListString(complaintData.whistleblower.gender, lists.genderList) : '---' }}</span>
				</td>
			</tr>
			<tr>
				<td style="width: 50%">
					<b>Teléfono:</b>
					<br />
					<span>{{ complaintData.whistleblower.phone ? (complaintData.whistleblower.phone | mask: '(00) 0000 0000') : '---' }}</span>
				</td>
				<td style="width: 50%">
					<b>Correo Electrónico:</b>
					<br />
					<span>{{ complaintData.whistleblower.email ? complaintData.whistleblower.email : '---' }}</span>
				</td>
			</tr>
			<tr>
				<td>
					<b>Notificación Mediante Correo Electrónico:</b>
					<br />
					<span>{{ complaintData.whistleblower.mailNotification ? 'Si' : 'No' }}</span>
				</td>
				<td>
					<b>Firma del Denunciante:</b>
					<img src="{{ complaintData.b64signature }}" />
				</td>
			</tr>
			<tr>
				<td style="width: 50%">
					<b>Pertenece a un Grupo de Vulnerabilidad:</b>
					<br />
					<span>{{ complaintData.whistleblower.vulnerabilityGroup ? 'Si' : 'No' }}</span>
				</td>
				<td style="width: 50%">
					<b>Se Encuentra Fuera de México:</b>
					<br />
					<span>{{ complaintData.whistleblower.outMexico ? 'Si' : 'No' }}</span>
				</td>
			</tr>
			<tr *ngIf="complaintData.whistleblower.outMexico">
				<td colspan="2" style="width: 50%">
					<b>Localidad Actual:</b>
					<br />
					<span>{{ complaintData.whistleblower.location ? complaintData.whistleblower.location : '---' }}</span>
				</td>
			</tr>
			<tr *ngIf="!complaintData.whistleblower.outMexico">
				<td style="width: 50%">
					<b>Estado:</b>
					<br />
					<span>{{ complaintData.whistleblower.state ? getListString(complaintData.whistleblower.state, lists.statesList) : '---' }}</span>
				</td>
				<td style="width: 50%">
					<b>Municipio:</b>
					<br />
					<span>{{ complaintData.whistleblower.municipality ? getListString(complaintData.whistleblower.municipality, lists.municipalityList) : '---' }}</span>
				</td>
			</tr>
			<tr *ngIf="!complaintData.whistleblower.outMexico">
				<td style="width: 50%">
					<b>Colonia:</b>
					<br />
					<span>{{ complaintData.whistleblower.colony ? complaintData.whistleblower.colony : '---' }}</span>
				</td>
				<td style="width: 50%">
					<b>Código Postal:</b>
					<br />
					<span>{{ complaintData.whistleblower.zipcode ? complaintData.whistleblower.zipcode : '---' }}</span>
				</td>
			</tr>
			<tr *ngIf="!complaintData.whistleblower.outMexico">
				<td colspan="2">
					<b>Calle:</b>
					<br />
					<span>{{ complaintData.whistleblower.street ? complaintData.whistleblower.street : '---' }}</span>
				</td>
			</tr>
			<tr *ngIf="!complaintData.whistleblower.outMexico">
				<td style="width: 50%">
					<b>No. Exterior:</b>
					<br />
					<span>{{ complaintData.whistleblower.extstreet ? complaintData.whistleblower.extstreet : '---' }}</span>
				</td>
				<td style="width: 50%">
					<b>No. Interior:</b>
					<br />
					<span>{{ complaintData.whistleblower.instreet ? complaintData.whistleblower.instreet : '---' }}</span>
				</td>
			</tr>
			<tr>
				<td style="width: 50%">
					<b>El Denunciante Forma Parte Del:</b>
					<br />
					<span>{{ complaintData.whistleblower.participantRole ? getListString(complaintData.whistleblower.participantRole, lists.participantRoleList) : '---' }}</span>
				</td>
				<td style="width: 50%">
					<b>Otro Tipo de Personal:</b>
					<br />
					<span>{{ complaintData.whistleblower.participantRoleOthers ? complaintData.whistleblower.participantRoleOthers : '---' }}</span>
				</td>
			</tr>
			<tr>
				<td style="width: 50%">
					<b>Instancia de Adscripción/Inscripción:</b>
					<br />
					<span>{{ complaintData.whistleblower.instance ? getListString(complaintData.whistleblower.instance, lists.instanceList) : '---' }}</span>
				</td>
				<td style="width: 50%">
					<b>Escuela/Dependencia:</b>
					<br />
					<span>{{ complaintData.whistleblower.entity ? getListString(complaintData.whistleblower.entity, lists.entityList) : '---' }}</span>
				</td>
			</tr>
			<tr>
				<td colspan="2">
					<b>Características Adicionales del Individuo:</b>
					<br />
					<span>{{ complaintData.whistleblower.characteristics ? (complaintData.whistleblower.characteristics | ucFirst) : '---' }}</span>
				</td>
			</tr>
		</tbody>
	</table>
</div>
